(function () {
	'use strict';

	angular
		.module('app')
		.factory('calendarEvents', [
			'$timeout',
			'$rootScope',
			'calendarIO',
			'seedcodeCalendar',
			'utilities',
			'dataStore',
			'environment',
			'manageCalendarActions',
			'manageFilters',
			calendarEvents,
		]);

	function calendarEvents(
		$timeout,
		$rootScope,
		calendarIO,
		seedcodeCalendar,
		utilities,
		dataStore,
		environment,
		manageCalendarActions,
		manageFilters
	) {
		var eventLastClicked = 0;
		var eventFetchWatcher;
		var lastFetchRequestID;
		var cancelFetchRequestID;
		var cancelFetchMessageActive;
		var linkedSelected;
		var linkedAfterRender;
		var filterState;
		var noEventsModalConfig = {};
		var hasVisibleEvents = false;
		var isSchedulesLoaded = false;
		var afterRenderShouldRun = false;
		var reportingCallback = {};
		var focusPinTimeout;

		return {
			reset: reset,
			setReportingCallback: setReportingCallback,
			getReportingCallback: getReportingCallback,
			isEventShown: isEventShown,
			setSchedulesLoaded: setSchedulesLoaded,
			getSchedulesLoaded: getSchedulesLoaded,
			viewRender: viewRender,
			beforeEventRender: beforeEventRender,
			onEventRender: onEventRender,
			onEventBeforeAllRender: onEventBeforeAllRender,
			onUnscheduledAfterAllRender: onUnscheduledAfterAllRender,
			onEventAfterAllRender: onEventAfterAllRender,
			onEventNoDrag: onEventNoDrag,
			onEventDragStart: onEventDragStart,
			onEventDrag: onEventDrag,
			onEventDrop: onEventDrop,
			onEventCancelDrop: onEventCancelDrop,
			onEventResizeStop: onEventResizeStop,
			onEventResize: onEventResize,
			onEventClick: onEventClick,
			showUnscheduledPopover: showUnscheduledPopover,
			onEventMouseOver: onEventMouseOver,
			onEventMouseOut: onEventMouseOut,
			onExternalEventDrop: onExternalEventDrop,
			unscheduledEventDrop: unscheduledEventDrop,
			onDayClick: onDayClick,
			onHorizonCollapse: onHorizonCollapse,
			onLoading: onLoading,
			onLoadingUnscheduled: onLoadingUnscheduled,
			cancelEventFetch: cancelEventFetch,
			onFetchStart: onFetchStart,
			onFetchEnd: onFetchEnd,
			onFetchUnscheduledStart: onFetchUnscheduledStart,
			onFetchUnscheduledEnd: onFetchUnscheduledEnd,
		};

		function reset() {
			hasVisibleEvents = false;
			isSchedulesLoaded = false;
			afterRenderShouldRun = false;
		}

		function setReportingCallback(id, callback) {
			reportingCallback[id] = callback;
		}

		function getReportingCallback(id) {
			return reportingCallback[id];
		}

		function isEventShown(event, constrainToView, timedView) {
			// If this is a map only schedule and there is no start, it should be shown
			if (event.schedule.isMapOnly && !event.start) {
				return true;
			}

			const view = seedcodeCalendar.get('view');
			const {start, end} = view;
			const outOfBounds =
				event.end.isBefore(start, 'day') ||
				event.start.isAfter(end, 'day') ||
				!manageFilters.isEventShown(event, constrainToView, timedView);

			return !outOfBounds;
		}

		function setSchedulesLoaded() {
			isSchedulesLoaded = true;

			try {
				$.fullCalendar.reportSchedulesLoaded(); // Report to fullcalendar that schedules have loaded
			} catch (error) {
				// Don't do anything on error
			}
		}

		function getSchedulesLoaded() {
			return isSchedulesLoaded;
		}

		function viewRender(view, element) {
			/* DEBUG */
			seedcodeCalendar.dev.eventCount = 0;
			/* END DEBUG */

			var date = moment(view.calendar.getDate().format('YYYY-MM-DD'));
			var config = seedcodeCalendar.get('config');
			var calendarActions = seedcodeCalendar.get('calendarActions');
			var viewChanged;
			//Did we change views?
			var previousView = seedcodeCalendar.get('view');
			if (!previousView || previousView.name !== view.name) {
				viewChanged = true;
			}

			seedcodeCalendar.init('view', view);
			seedcodeCalendar.init('date', {
				selected: date,
				nav: date,
				preventUpdate: true,
			});

			config.defaultDate = moment(date).format('YYYY-MM-DD');
			config.defaultView = view.name;

			//Write data to save state
			dataStore.saveState('date', config.defaultDate);
			dataStore.saveState('view', view.name);

			//Run calendar actions
			if (
				viewChanged &&
				calendarActions &&
				calendarActions.afterViewChanged
			) {
				//Run load actions
				manageCalendarActions.runCalendarActions(
					calendarActions.afterViewChanged,
					null
				);
			}

			$rootScope.$broadcast('viewRendered');
		}

		function beforeEventRender(event, events, type) {
			// Run before event rendered action
			var actionResult;
			var actionCallbacks;
			var preventDefaultOverride;
			var hideEvent;

			// Run any attached event actions
			actionCallbacks = {
				confirm: function () {
					preventDefaultOverride = true;
				},
				cancel: function () {
					preventDefaultOverride = false;
				},
			};
			var params = {
				data: {type: type || 'event'},
			};

			var actionResult = calendarIO.runEventActions(
				'beforeEventRendered',
				event,
				true,
				actionCallbacks,
				null,
				null,
				null,
				null,
				null,
				null,
				params
			);

			return !actionResult && !preventDefaultOverride ? true : false;
		}

		function onEventRender(event, element, view) {
			/* DEBUG */
			if (seedcodeCalendar.dev.queryStart) {
				seedcodeCalendar.dev.eventCount = seedcodeCalendar.dev
					.eventCount
					? seedcodeCalendar.dev.eventCount + 1
					: 1;
			}
			/* END DEBUG */

			if (!hasVisibleEvents) {
				// The calendar view currently has visible events
				hasVisibleEvents = true;
			}
			//Show popover if it's a new event or the event was flagged to show popover on render
			if (event.eventStatus && event.eventStatus.showPopover === true) {
				//The timeout here keeps this playing nice with angular's digest cycle.
				$timeout(function () {
					var position = element.offset();

					//Check to see if the element has the same coordinates as the element that was clicked. If it does that is the element to launch the popover from
					if (
						!view.popoverRendered &&
						(!event.eventStatus.position ||
							(position.left ===
								event.eventStatus.position.left &&
								position.top ===
									event.eventStatus.position.top))
					) {
						calendarIO.showEventPopover(event, element, view);
					}
				}, 0);
			}

			const {type, id, parentElementQuery, forceEqualSides} =
				calendarIO.getFocusEvent();
			if (id === event._id && type === 'event') {
				if (focusPinTimeout) {
					window.clearTimeout(focusPinTimeout);
				}
				focusPinTimeout = window.setTimeout(() => {
					calendarIO.focusEvent(
						event,
						type,
						parentElementQuery,
						forceEqualSides
					);
				}, 0);
			}
		}

		//FullCalendar callback when loading events, used to display loaders
		function onLoading(result) {
			var slowLoadingTimeout = 5000;
			removeNoEventsModal();
			seedcodeCalendar.init('loading-events', result.isLoading);

			if (result.isLoading) {
				if (cancelFetchMessageActive) {
					utilities.hideMessages('message');
					cancelFetchMessageActive = null;
				}
				lastFetchRequestID = result.fetchID;
				loadingWatcher(result, slowLoadingTimeout, true);
			} else if (!result.isLoading) {
				if (cancelFetchMessageActive) {
					utilities.hideMessages('message');
					cancelFetchMessageActive = null;
				}
				window.clearTimeout(eventFetchWatcher);
				eventFetchWatcher = null;
				lastFetchRequestID = null;
			}
		}

		function loadingWatcher(result, timeoutDuration, firstRun) {
			var messageHoldDuration = 8000;
			window.clearTimeout(eventFetchWatcher);
			eventFetchWatcher = window.setTimeout(
				function () {
					var loadingMessage =
						'<span translate>Waiting for events</span>' +
						'<span class="message-separator"> | </span>' +
						'<span class="text-danger" translate>Cancel Request</span>' +
						'<span class="message-icon-separator" style="opacity: 0.8;"><i class="fa fa-lg fa-times text-danger"></i></span>';

					cancelFetchMessageActive = true;
					utilities.showMessage(
						loadingMessage,
						0,
						0,
						null,
						function () {
							result.cancelLoadingCallback(result.fetchID);
							cancelEventFetch(result.fetchID, result.source);
						}
					);

					//Update system health analytics
					var trackProperties = {};
					trackProperties.sourcetype = result.source
						? result.source.name
						: '';
					utilities.messageToSystemHealth(
						'slowQueryWarning',
						trackProperties
					);
				},
				firstRun
					? timeoutDuration
					: timeoutDuration + messageHoldDuration
			);
		}

		function cancelEventFetch(fetchID, source) {
			var clearEvents = fetchID ? true : false;

			if (!eventFetchWatcher && !fetchID) {
				return;
			}
			if (!fetchID) {
				fetchID = lastFetchRequestID;
			}
			cancelFetchRequestID = fetchID;
			if (lastFetchRequestID === fetchID) {
				cancelFetchMessageActive = false;
				window.clearTimeout(eventFetchWatcher);
				if (clearEvents) {
					seedcodeCalendar
						.get('element')
						.fullCalendar('removeEvents');
				}
				lastFetchRequestID = null;
				seedcodeCalendar.init('loading-events', false);
			}
		}

		function onFetchStart(result) {
			// Could runt something each time an event fetch request is started
		}

		function onFetchEnd(result) {
			if (
				cancelFetchRequestID &&
				cancelFetchRequestID === result.fetchID
			) {
				return true;
			}

			return false;
		}

		function onLoadingUnscheduled(result) {}

		function onFetchUnscheduledStart(result) {
			// Could runt something each time an event fetch request is started
		}

		function onFetchUnscheduledEnd(result) {
			return false;
		}

		function onUnscheduledAfterAllRender(events) {
			$rootScope.$broadcast('unscheduledRendered', events);
		}

		function onEventBeforeAllRender() {
			hasVisibleEvents = null;
		}

		function onEventAfterAllRender(
			options,
			view,
			actionOnly,
			fromBookmark
		) {
			if (!options) {
				options = {};
			}
			if (!view) {
				view = seedcodeCalendar.get('view');
			}

			const calendarActions = seedcodeCalendar.get('calendarActions');
			const schedules = seedcodeCalendar.get('schedules');
			const schedulesLoaded = seedcodeCalendar.get('schedulesLoaded');
			const loading = seedcodeCalendar.get('loading-events');

			const changedFilters = JSON.parse(
				JSON.stringify(seedcodeCalendar.get('changedFilters'))
			);
			const changedSchedules = JSON.parse(
				JSON.stringify(seedcodeCalendar.get('changedSchedules'))
			);

			const loaded =
				!!schedules?.length &&
				schedulesLoaded &&
				!!view?.name &&
				!loading;

			let firstAfterRendered = false;

			if (!afterRenderShouldRun && loaded) {
				afterRenderShouldRun = true;
				firstAfterRendered = true;
			}

			// Set global filter state values
			filterState = manageFilters.getFilterState();

			seedcodeCalendar.init('changedFilters', [], true);
			seedcodeCalendar.init('changedSchedules', [], true);
			seedcodeCalendar.init('renderHasSelectedSchedule', null, true);

			const triggers = {
				fromRefresh: !!options.refresh,
				fromResize: !!options.resize,
				fromViewStateChange:
					hasViewStateChanged() || firstAfterRendered,
				fromFilterChange:
					changedFilters && changedFilters.length ? true : false,
				fromScheduleChange:
					changedSchedules && changedSchedules.length ? true : false,
				fromBookmark: fromBookmark,
			};
			//Can we track this based on how many sources have been added (add source function)

			//Run calendar actions
			if (calendarActions && calendarActions.afterEventsRendered) {
				// Only run action if the schedules have been loaded and the view object has been set
				if (loaded) {
					//Run load actions
					const paramsData = {
						fromRefresh: triggers.fromRefresh,
						fromResize: triggers.fromResize,
						fromViewStateChange: triggers.fromViewStateChange,
						fromFilterChange: triggers.fromFilterChange,
						changedFilters: changedFilters,
						fromScheduleChange: triggers.fromScheduleChange,
						changedSchedules: changedSchedules,
						fromBookmark: triggers.fromBookmark,
					};

					manageCalendarActions.runCalendarActions(
						calendarActions.afterEventsRendered,
						null,
						paramsData
					);
				}
			}

			//Add additional properties to options
			options.schedulesLoaded = schedulesLoaded;

			// Run callbacks
			const mapCallback = getReportingCallback('mapEvents');

			let mapRecenter = false;

			if (mapCallback && !options.resize) {
				let onlyQueryOnGeocode = false;
				let hasSelected = false;

				// don't recenter on resize options.resize
				if (
					triggers.fromRefresh ||
					triggers.fromViewStateChange ||
					triggers.fromFilterChange ||
					triggers.fromScheduleChange ||
					triggers.fromBookmark
				) {
					mapRecenter = true;
				}

				if (changedSchedules?.length) {
					onlyQueryOnGeocode = true;
					for (const schedule of changedSchedules) {
						if (!schedule.queryOnGeocode) {
							onlyQueryOnGeocode = false;
						}
						if (schedule.status.selected) {
							hasSelected = true;
						}
					}
				}
				mapCallback(
					null,
					!mapRecenter,
					onlyQueryOnGeocode && hasSelected
				);
			}

			//Broadcast that event rendering is complete
			if (fromBookmark) {
				return;
			}

			$rootScope.$broadcast('eventsRendered', options);

			if (schedulesLoaded) {
				if (!seedcodeCalendar.get('loading-events')) {
					if (!hasVisibleEvents) {
						showNoEventsModal();
					} else {
						removeNoEventsModal();
					}
				}
			} else {
				// Reset modal config if there are no schedules as this would mean first load
				noEventsModalConfig = {};
			}

			if (actionOnly) {
				return;
			}

			//Potentially was set in the onEventRender function. Used to prevent multiple popovers on render
			view.popoverRendered = undefined;

			//Update multi-select styling
			calendarIO.toggleMultiSelect(
				null,
				false,
				null,
				seedcodeCalendar.get('view')
			);

			if (linkedAfterRender) {
				selectLinkedEvents(
					linkedAfterRender,
					seedcodeCalendar.get('view')
				);
				linkedAfterRender = null;
			}

			//Dev logging
			seedcoder.logSplitTime(
				'render complete for ' +
					seedcodeCalendar.dev.eventCount +
					' events',
				true
			);
			/* DEBUG */
			if (seedcodeCalendar.dev.queryStart) {
				seedcodeCalendar.dev.queryTime =
					(new Date().getTime() - seedcodeCalendar.dev.queryStart) /
					1000;
				seedcodeCalendar.dev.queryStart = null;
			}
			/* END DEBUG */
		}

		function hasViewStateChanged() {
			var changed;
			var view = seedcodeCalendar.get('view');
			var config = seedcodeCalendar.get('config');
			var previousViewRenderSettings = seedcodeCalendar.get(
				'previousViewRenderSettings'
			);

			var viewSettings = {
				viewName: view.name,
				viewStart: view.start.format(),
				viewEnd: view.end.format(),
				resourceColumns: config.resourceColumns,
				resourceDays: config.resourceDays,
				weekCount: config.weekCount,
				resourcePosition: config.resourcePosition,
			};

			for (var property in viewSettings) {
				// We don't care about the data type so no === that way string vs number doesn't matter here
				if (
					viewSettings[property] !=
					previousViewRenderSettings[property]
				) {
					previousViewRenderSettings[property] =
						viewSettings[property];
					changed = true;
				}
			}

			if (changed) {
				return true;
			}

			return false;
		}

		function removeNoEventsModal() {
			if (noEventsModalConfig && noEventsModalConfig.show) {
				let modalContainer = seedcodeCalendar.get('element')[0];
				let currentModalElement = modalContainer
					? modalContainer.querySelector('.non-modal')
					: document.querySelector('.non-modal');
				if (currentModalElement) {
					currentModalElement.remove();
					noEventsModalConfig.show = false;
					return true;
				}
			}
			return false;
		}

		function showNoEventsModal() {
			if (!noEventsModalConfig) {
				noEventsModalConfig = {};
			}

			let title = 'No events found';
			let buttonText = filterState.hasSelection ? 'Clear Filters' : '';

			let modalContainer = seedcodeCalendar.get('element')?.[0];
			if (!modalContainer) {
				return;
			}

			let template =
				'<div style="background: rgba(0,0,0,0.75); color: white;">' +
				'<div class="pad-large text-center">' +
				'<h4 translate>' +
				title +
				'</h4>' +
				(buttonText
					? '<button translate style="pointer-events: auto;" class="btn btn-link dbk_button_link" ng-click="popover.config.confirmFunction();">' +
						buttonText +
						'</button>'
					: '') +
				'</div>' +
				'</div>';

			if (noEventsModalConfig.show === true) {
				let wasRemoved = removeNoEventsModal();
				if (!wasRemoved) {
					return;
				}
			}

			noEventsModalConfig.container = modalContainer;
			noEventsModalConfig.type = 'non-modal'; // modal or popover
			noEventsModalConfig.controller = 'ModalCtrl';
			noEventsModalConfig.class = 'no-events-modal';
			noEventsModalConfig.destroy = true;
			noEventsModalConfig.confirmFunction = clearAllFilters;
			noEventsModalConfig.show = true;

			utilities.popover(noEventsModalConfig, template);

			function clearAllFilters() {
				manageFilters.clearFilters();
			}
		}

		function onEventNoDrag(event, jsEvent, ui, view) {
			// Don't allow dragging for
			// event.isUnavailable
			// config.status.preventDrag

			// If the event is specifically not editable then show a message
			if (!event?.schedule?.editable) {
				utilities.showMessage(
					'Cannot modify a read only calendar',
					0,
					6000
				);
			}
		}
		function onEventDragStart(event, jsEvent, ui, view) {
			var config = seedcodeCalendar.get('config');
			config.status.isDragging = true;
			//Mark this event as being actively dragged in the edit queue
			var editQueue = event.schedule.source.getEventEditQueue();
			if (editQueue[event.eventID]) {
				editQueue.dragging = [event.eventID];
			}

			event.beforeDrop = calendarIO.beforeDrop(event);
			$rootScope.$broadcast('closePopovers');

			//If a focus action exists run it now
			if (event.schedule.source && event.schedule.source.eventFocus) {
				event.schedule.source.eventFocus(event.schedule, event);
			}
		}

		function onEventDrag(event, jsEvent, ui, view) {
			// Do something while dragging
		}

		function onEventDrop(
			event,
			revertFunc,
			jsEvent,
			ui,
			breakoutData,
			isClone,
			isAltView,
			view
		) {
			// breakoutData is an object containing field and value properties if drag and drop was for a breakout item
			if (isAltView) {
				// Check if unscheduled is allowed
				if (!event.schedule.allowUnscheduled) {
					utilities.showModal(
						'Failed To Save as Unscheduled',
						'Unscheduled has not been enabled for this calendar. A DayBack administrator will need to enable unscheduled to add events here from this calendar.',
						null,
						null,
						'OK',
						null
					);
					if (revertFunc) {
						revertFunc();
					}
					return;
				}

				// Check if unscheduled is mapped
				if (
					!event.schedule.fieldMap.unscheduled ||
					event.schedule.unusedMap.unscheduled
				) {
					utilities.showModal(
						'Failed To Save as Unscheduled',
						'There is not currently an unscheduled field mapped for this calendar. A DayBack administrator will need to map a field to unscheduled before you can change that property.',
						null,
						null,
						'OK',
						null
					);
					if (revertFunc) {
						revertFunc();
					}
					return;
				}
				event.eventStatus.focus = true;
				if (isClone) {
					unscheduledEventDrop(
						event.start ? event.start.clone() : null,
						event.end ? event.end.clone() : null,
						event,
						breakoutData,
						isClone
					);
					return;
				}
				seedcodeCalendar
					.get('element')
					.fullCalendar('eventToUnscheduled', event);
				calendarIO.updateEventTime(event, () => {
					revertFunc();
					seedcodeCalendar
						.get('element')
						.fullCalendar('unscheduledToEvent', event);
				});
			} else {
				applyEventDrop(event, isClone, revertFunc);
			}
		}

		function applyEventDrop(event, isClone, revertFunc) {
			calendarIO.clearEventDragStatus(event);

			if (isClone) {
				if (event.hasAttachments) {
					utilities.showModal(
						'Attachments',
						'The original event has attachments',
						'Cancel',
						null,
						'OK',
						function () {
							calendarIO.duplicateEvent(event);
						}
					);
				} else {
					calendarIO.duplicateEvent(event);
				}
			} else {
				calendarIO.updateEventTime(event, revertFunc);
			}
		}

		function onEventCancelDrop(event, jsEvent, ui, breakoutData, view) {
			var breakoutField = breakoutData ? breakoutData.field : null;
			var breakoutRestriction = breakoutData
				? breakoutData.restriction
				: null;

			var config = seedcodeCalendar.get('config');
			if (breakoutField) {
				var breakout = config.breakout;
				var breakoutLabel = breakout ? breakout.label : breakoutField;

				// if (breakoutRestriction === 'noFilterDrag') {
				// 	utilities.showModal('Cannot Save Changes', 'There are multiple values in the select list for "' + breakoutLabel + '".', null, null, 'OK', null);
				// }
				if (breakoutRestriction === 'schedule') {
					utilities.showModal(
						'Cannot Save Changes',
						'Changing calendars by dragging events is not supported. All changes have been reverted.',
						null,
						null,
						'OK',
						null
					);
				} else if (
					breakoutRestriction === 'contactName' ||
					breakoutRestriction === 'projectName'
				) {
					utilities.showModal(
						'Cannot Save Changes',
						'Changing values in the "' +
							breakout.label +
							'" field by dragging events is not supported. All changes have been reverted.',
						null,
						null,
						'OK',
						null
					);
				} else {
					utilities.showModal(
						'Cannot Save Changes',
						'The calendar "' +
							event.schedule.name +
							'" does not have the field "' +
							breakoutLabel +
							'". All changes have been reverted.',
						null,
						null,
						'OK',
						null
					);
				}
				return;
			}
			calendarIO.clearEventDragStatus(event);
		}

		function onEventResize(event, revertFunc, jsEvent, ui, view) {
			calendarIO.clearEventDragStatus(event);
			calendarIO.updateEventTime(event, revertFunc);
		}

		function onEventResizeStop(event) {
			calendarIO.clearEventDragStatus(event);
		}

		function onEventMouseOver(event, jsEvent, view) {
			var element = $(jsEvent.currentTarget);

			var actionCallbacks = {
				confirm: function (action) {
					// Some functionality can go here that represents the confirm callback
				},
				cancel: function (action) {
					//Some functionality can go here that represents a cancel callback
				},
			};

			var actionResult = calendarIO.runEventActions(
				'eventHover',
				event,
				true,
				actionCallbacks,
				null,
				null,
				null,
				null,
				element
			);
		}

		function onEventMouseOut(event, jsEvent, view) {
			//Event mouse out actions could go here
		}

		/**
		 * @param {object} dateStart - a moment object
		 * @param {object} dateEnd - a moment object
		 * @param {MouseEvent} jsEvent - a jquery mouse event
		 * @param {object} ui - jqueryUI object
		 * @param {object} breakoutData - breakout object containing field and value
		 */
		function onExternalEventDrop(
			dateStart,
			dateEnd,
			jsEvent,
			ui,
			breakoutData,
			isClone
		) {
			// Check if we are dragging from unscheduled to the calendar
			if (ui?.helper?.data?.unscheduled) {
				const unscheduledEvent = ui.helper.data;
				unscheduledEventDrop(
					dateStart,
					dateEnd,
					unscheduledEvent,
					breakoutData,
					isClone
				);
			} else {
				newEventDrop(dateStart, ui, breakoutData);
			}
		}

		/**
		 * @param {object} dateStart - a moment object
		 * @param {object} ui - jqueryUI object
		 * @param {object} breakoutData - breakout object containing field and value
		 */
		function newEventDrop(dateStart, ui, breakoutData) {
			//Make sure we dropped our item in a valid spot and if so add the dropped class
			if (!ui?.helper?.hasClass('cancel-drop')) {
				const config = seedcodeCalendar.get('config');
				const allDay = dateStart.hasTime() ? false : true;
				const eventTitle = config.newEventTitleText;
				let isUnscheduled;
				if (ui?.helper?.data?.isUnscheduled) {
					isUnscheduled = true;
					ui.helper.data.isUnscheduled = null;
				}
				ui.helper.addClass('dropped');
				calendarIO.newEvent(
					dateStart,
					allDay,
					eventTitle,
					breakoutData,
					isUnscheduled
				);
			}
		}

		/**
		 * @param {object} dateStart - a moment object
		 * @param {object} dateEnd - a moment object
		 * @param {object} unscheduledEvent - event object
		 * @param {object} breakoutData - breakout object containing field and value
		 */
		function unscheduledEventDrop(
			dateStart,
			dateEnd,
			unscheduledEvent,
			breakoutData,
			isClone
		) {
			// Check for unscheduled field mapping
			const breakout = breakoutData
				? {
						field: breakoutData.field,
						value: Array.isArray(
							unscheduledEvent[breakoutData.field]
						)
							? [breakoutData.value.name]
							: breakoutData.value.name,
					}
				: null;

			if (
				breakoutData?.field === 'schedule' &&
				breakoutData?.value?.id !==
					utilities.stringToID(unscheduledEvent.schedule.id)
			) {
				utilities.showModal(
					'Changing Calendars Not Supported',
					"Dragging an event to a new calendar is currently unsupported. Would you like to schedule this event to the same calendar it's already on?",
					'No',
					null,
					'Yes',
					function () {
						processDropFromUnscheduled();
					}
				);
			} else if (isClone) {
				const view = seedcodeCalendar.get('view');
				let clonedEvent;

				if (dateStart) {
					clonedEvent = view.duplicateEvent(
						unscheduledEvent,
						dateStart,
						dateEnd
					);
					// Adjust all day exclusive end dates if this was an all day event and it's moving to an all day slot
					if (clonedEvent.allDay && !dateStart.hasTime()) {
						clonedEvent.end.subtract(1, 'days');
					}

					// Clean up event properties for duplication
					// If the event was unscheduled then it is being dragged to calendar
					// and the unscheduled property should be deleted
					delete clonedEvent.unscheduled;
					// If the event was previously not unscheduled
					// then it means dragging from calendar to unscheduled
					if (!unscheduledEvent.unscheduled) {
						clonedEvent.unscheduled = true;
					}
					delete clonedEvent.allDay; // Remove all day flag because it will be determined by destination dates / times
					if (breakout?.field) {
						clonedEvent[breakout.field] = breakout.value;
					}
				} else {
					clonedEvent = unscheduledEvent;
				}

				applyEventDrop(clonedEvent, isClone);
			} else {
				processDropFromUnscheduled();
			}

			function processDropFromUnscheduled() {
				// Set before drop object before making any changes
				unscheduledEvent.beforeDrop =
					calendarIO.beforeDrop(unscheduledEvent);

				seedcodeCalendar
					.get('element')
					.fullCalendar(
						'unscheduledToEvent',
						unscheduledEvent,
						dateStart,
						dateEnd,
						breakout,
						saveEvent
					);
			}

			function saveEvent(event, unscheduledPosition, revertFunc) {
				calendarIO.updateEventTime(event, () => {
					// Undo function
					revertFunc();
					seedcodeCalendar
						.get('element')
						.fullCalendar(
							'eventToUnscheduled',
							event,
							unscheduledPosition
						);
				});
			}

			return;
		}

		function onDayClick(date, jsEvent, view) {
			//Exit if we are on a phone
			if (environment.isPhone) {
				return;
			}
			var hasNoEventsModal;
			var config = seedcodeCalendar.get('config');
			var allDay = date._ambigTime ? true : false;
			var breakoutData;
			var container = '.fc-content';
			if (
				angular.element(jsEvent.target).parents('.fc-slot-scroll')
					.length
			) {
				container = '.fc-slot-scroll';
			}

			if (view.name === 'basicHorizon') {
				breakoutData =
					config.horizonBreakoutField && jsEvent.resource
						? {
								field: config.horizonBreakoutField,
								value: jsEvent.resource,
							}
						: null;
			} else {
				breakoutData = jsEvent.resource
					? {field: 'resource', value: jsEvent.resource}
					: null;
			}

			var values = {
				selectedDate: date,
				selectedAllDay: allDay,
				selectedBreakoutData: breakoutData,
				view: view.name,
			};

			var popover = {
				controller: 'NewEventCtrl',
				target: jsEvent.target,
				container: container,
				type: 'popover', // modal or popover
				// width: 700,
				positionX: jsEvent.pageX,
				positionY: jsEvent.pageY,
				direction: 'auto',
				data: values,
				dataTitle: 'newEvent',
				destroy: true,
				onShow: '',
				onShown: function () {
					// Close no events modal
					hasNoEventsModal = removeNoEventsModal();
				},
				onHide: '',
				onHidden: function (content, config) {
					if (hasNoEventsModal && !config.selection) {
						showNoEventsModal();
					}
				},
				show: true,
			};

			utilities.popover(
				popover,
				'<div ng-include="\'app/calendar/event-new.html\'"></div>'
			);
		}

		function onEventClick(event, jsEvent, view, clickTarget, typeOverride) {
			return new Promise((resolve, reject) => {
				if (!jsEvent) {
					reject();
					return;
				}
				var clickedElement = clickTarget ? clickTarget : jsEvent.target;
				var clickedTag = clickedElement ? clickedElement.tagName : null;

				//Check if a link was clicked
				if (clickedTag && clickedTag.toLowerCase() === 'a') {
					// Don't do anything if a link was clicked
					resolve();
					return;
				}

				if (
					(clickedElement &&
						clickedElement.className === 'linked-event') ||
					(clickedElement &&
						clickedElement.parentElement &&
						clickedElement.parentElement.className ===
							'linked-event')
				) {
					// Run link clicked routine
					selectLinkedEvents(event, view);
					resolve();
					return;
				}

				// Check if unavaialble after links to allow for clicking links
				if (event.isUnavailable) {
					resolve();
					return false;
				}

				if (!event.schedule.fieldMap.eventID) {
					//no field mapping
					var message =
						"This event can't be opened because the field mapping has not been completed for " +
						event.schedule.name +
						'.<span class="message-icon"><i class="fa fa-question-circle fa-lg dbk_icon_help" style="display: inline-block; vertical-align: middle;"></i></span>';
					utilities.showMessage(
						message,
						0,
						7000,
						'error',
						errorFunction,
						false
					);
					reject();
					return;
				}

				if (!event.eventID && !event?.eventStatus.create) {
					//no field mapping
					var message =
						"This event can't be opened because the id field is empty. Please check the field mapping for " +
						event.schedule.name +
						'.<span class="message-icon"><i class="fa fa-question-circle fa-lg dbk_icon_help" style="display: inline-block; vertical-align: middle;"></i></span>';
					utilities.showMessage(
						message,
						0,
						7000,
						'error',
						errorFunction,
						false
					);
					reject();
					return;
				}

				var doubleClickTimeout = jsEvent && jsEvent.shiftKey ? 0 : 800; //(in milliseconds)
				var popoverTarget = clickTarget
					? clickTarget
					: jsEvent.currentTarget;
				var openPopover =
					linkedSelected &&
					event.metadata &&
					linkedSelected === event.metadata.linkedID
						? true
						: calendarIO.toggleMultiSelect(
								event,
								jsEvent.shiftKey,
								jsEvent.currentTarget,
								view
							);

				var clickedTimestamp = new Date().getTime();
				//If the event is in progress of saving don't open new popover as the event will re-render
				if (
					!event ||
					clickedTimestamp < eventLastClicked + doubleClickTimeout
				) {
					resolve();
					return;
				}

				//Update global timestamp of last click
				eventLastClicked = clickedTimestamp;
				if (openPopover) {
					if (
						!event?.eventStatus?.create &&
						(!event.eventID || !event.schedule.fieldMap.eventID)
					) {
						if (!event.eventStatus) {
							event.eventStatus = {};
						}
						event.eventStatus.showPopover = true;
					} else {
						return calendarIO.editPopoverCreate(
							event,
							popoverTarget,
							null,
							jsEvent,
							null,
							null,
							null,
							(editPopover) => {
								if (editPopover) {
									resolve(editPopover);
								} else {
									reject();
								}
							},
							typeOverride
						);
					}
				}
			});

			function errorFunction() {
				utilities.help('Field Mapping', '136-field-mapping');
			}
		}

		async function showUnscheduledPopover(event, element) {
			if (event?.eventStatus?.showPopover) {
				return await calendarIO.showEventPopover(
					event,
					$(element),
					seedcodeCalendar.get('view')
				);
			}
		}

		function onHorizonCollapse(
			breakoutField,
			breakoutItems,
			collapsedItem
		) {
			var output = [];
			var config = seedcodeCalendar.get('config');

			for (var i = 0; i < breakoutItems.length; i++) {
				if (
					breakoutItems[i].status &&
					breakoutItems[i].status.collapsed
				) {
					output.push(breakoutItems[i].name);
				}
			}

			if (config.isShare) {
				config[breakoutField + 'Collapsed'] = JSON.stringify(output);
			} else {
				dataStore.saveState(
					breakoutField + 'Collapsed',
					JSON.stringify(output)
				);
			}
		}

		function selectLinkedEvents(event, view) {
			var linkedRangeStart;
			var linkedRangeEnd;
			var linkedRangeDuration;
			var linkedAdjustedEnd;
			var adjusted;

			var config = seedcodeCalendar.get('config');
			var multiSelect = seedcodeCalendar.get('multiSelect');

			var linkedID =
				event.metadata && event.metadata.linkedID
					? event.metadata.linkedID
					: null;
			var linkedRange =
				event.metadata && event.metadata.linkedRange
					? event.metadata.linkedRange
					: null;
			var selected = linkedSelected && linkedSelected === linkedID;

			if (linkedRange) {
				linkedRange = linkedRange.split(',');
				linkedRangeStart = moment(linkedRange[0].trim(), 'YYYY-MM-DD');
				linkedRangeEnd = moment(linkedRange[1].trim(), 'YYYY-MM-DD');

				if (linkedRangeStart.isBefore(view.start)) {
					linkedRangeStart.subtract(1, 'day');
					config.defaultDate = linkedRangeStart
						.clone()
						.subtract(1, 'day');
					adjusted = true;
				} else {
					linkedRangeStart = view.start.clone();
				}

				linkedRangeDuration = linkedRangeEnd.diff(
					linkedRangeStart,
					'days'
				);

				linkedAdjustedEnd = linkedRangeStart
					.clone()
					.add(linkedRangeDuration, 'days');

				if (!linkedAdjustedEnd.isBefore(view.end)) {
					config.horizonSlider = $.fullCalendar.horizonDaysToSlider(
						linkedRangeDuration + 2
					);
					adjusted = true;
				}

				if (adjusted) {
					linkedAfterRender = event;
					seedcodeCalendar.init('initCalendar');
					return;
				}
			}

			if (selected) {
				linkedSelected = null;
			} else {
				linkedSelected = linkedID;
			}

			var eventElement = document.querySelector(
				'[data-id="' + event._id + '"]'
			);

			if (!selected) {
				var openPopover = calendarIO.toggleMultiSelect(
					event,
					false,
					eventElement,
					view
				);
			}

			var clientEvents = seedcodeCalendar
				.get('element')
				.fullCalendar('clientEvents');
			for (var i = 0; i < clientEvents.length; i++) {
				if (
					clientEvents[i].metadata &&
					clientEvents[i].metadata.linkedID &&
					clientEvents[i].metadata.linkedID === linkedID
				) {
					eventElement = $('[data-id="' + clientEvents[i]._id + '"]');
					if (eventElement && eventElement.length) {
						var openPopover = calendarIO.toggleMultiSelect(
							clientEvents[i],
							true,
							eventElement,
							view
						);
					}
				}
			}
		}
	}
})();
